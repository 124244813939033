var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: function (isVisible, entry) {
      _vm.changeAnchor(isVisible, entry);
    },
    intersection: {
      threshold: 0.8
    },
  }),expression:"{\n    callback: (isVisible, entry) => {\n      changeAnchor(isVisible, entry);\n    },\n    intersection: {\n      threshold: 0.8\n    },\n  }"}],staticClass:"overflow-hidden accent px-5 py-10",attrs:{"id":"about"}},[_c('v-row',{staticClass:"mb-10",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',[_c('base-heading',{staticClass:"info--text text-center"},[_vm._v(" À propos de moi ")])],1)],1),_c('v-row',{staticClass:"px-5 py-10",attrs:{"justify":"space-around","no-gutters":""}},[_c('v-col',{class:'d-flex ' + (_vm.$vuetify.breakpoint.mobile ? 'flex-column' : 'flex-fill'),attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? 12 : 5}},[_c('div',{staticClass:"d-flex align-self-center flex-column text-center mr-5 mb-5"},[_c('v-avatar',{attrs:{"size":"256","rounded":""}},[_c('v-img',{attrs:{"src":require("@/assets/profil.png"),"alt":"Pierre"}})],1),_c('div',[_c('b',[_vm._v("Pierre FACQ")])]),_c('div',[_c('i',[_vm._v("Full Stack Developer")])])],1),_c('div',{staticClass:"d-flex align-center"},[_c('base-text',{staticClass:"text-justify"},[_vm._v(" Intrigué par les énigmes, enquêtes et autres casse-têtes depuis l'enfance, je prends plaisir à étudier puis construire la meilleure solution en prenant compte des éléments à ma disposition. "),_c('br'),_c('br'),_vm._v(" Double diplomé en ingénierie informatique et en expertise statistique, je combine penchant naturel et compétences afin de vous garantir une expérience réussie. "),_c('br'),_c('br'),_c('a',{staticClass:"info--text",staticStyle:{"text-decoration":"none"},attrs:{"href":'mailto:pierrefacq.business@gmail.com'}},[_vm._v(" Démarrons ensemble votre prochain projet ! ")])])],1)]),_c('v-col',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
        callback: function (isVisible) {
          _vm.startAnimations(isVisible);
        },
        intersection: {
          threshold: 0.1
        },
      }),expression:"{\n        callback: (isVisible) => {\n          startAnimations(isVisible);\n        },\n        intersection: {\n          threshold: 0.1\n        },\n      }"}],staticClass:"d-flex align-center flex-column",attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? 12 : 5}},_vm._l((_vm.skills),function(skill,i){return _c('v-row',{key:i,style:({
          color: _vm.$vuetify.theme.currentTheme.primary,
          width: '100%'
        })},[_c('v-col',{staticClass:"text-uppercase",attrs:{"cols":"12"},domProps:{"textContent":_vm._s(skill.category)}}),_vm._l((skill.items),function(item,j){return _c('v-progress-linear',{key:j,staticClass:"mb-2",attrs:{"value":_vm.calculateProgress(item.displayedValue),"color":"warning","height":"25"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"text-center",style:({
                  borderRight: '2px solid',
                  backgroundColor: _vm.$vuetify.theme.currentTheme.primary,
                }),attrs:{"cols":"4"}},[_c('span',{staticClass:"warning--text"},[_vm._v(_vm._s(item.name))])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"8"}},[_c('strong',{staticClass:"primary--text"},[_vm._v(_vm._s(Math.ceil(item.displayedValue))+"%")])])],1)]},proxy:true}],null,true)})})],2)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }