<template>
  <section
    id="about"
    v-observe-visibility="{
      callback: (isVisible, entry) => {
        changeAnchor(isVisible, entry);
      },
      intersection: {
        threshold: 0.8
      },
    }"
    class="overflow-hidden accent px-5 py-10"
  >
    <v-row
      class="mb-10"
      justify="center"
      no-gutters
    >
      <v-col>
        <base-heading class="info--text text-center">
          À propos de moi
        </base-heading>
      </v-col>
    </v-row>
    <v-row
      class="px-5 py-10"
      justify="space-around"
      no-gutters
    >
      <v-col
        :class="'d-flex ' + ($vuetify.breakpoint.mobile ? 'flex-column' : 'flex-fill')"
        :cols="$vuetify.breakpoint.mobile ? 12 : 5"
      >
        <div class="d-flex align-self-center flex-column text-center mr-5 mb-5">
          <v-avatar
            size="256"
            rounded
          >
            <v-img
              :src="require(`@/assets/profil.png`)"
              alt="Pierre"
            />
          </v-avatar>
          <div><b>Pierre FACQ</b></div>
          <div><i>Full Stack Developer</i></div>
        </div>

        <div class="d-flex align-center">
          <base-text class="text-justify">
            Intrigué par les énigmes, enquêtes et autres casse-têtes depuis l'enfance,
            je prends plaisir à étudier puis construire la meilleure solution en prenant compte des éléments à ma disposition.
            <br>
            <br>
            Double diplomé en ingénierie informatique et en expertise statistique,
            je combine penchant naturel et compétences afin de vous garantir une expérience réussie.
            <br>
            <br>
            <a
              class="info--text"
              :href="'mailto:pierrefacq.business@gmail.com'"
              style="text-decoration: none"
            >
              Démarrons ensemble votre prochain projet !
            </a>
          </base-text>
        </div>
      </v-col>
      <v-col
        v-observe-visibility="{
          callback: (isVisible) => {
            startAnimations(isVisible);
          },
          intersection: {
            threshold: 0.1
          },
        }"
        class="d-flex align-center flex-column"
        :cols="$vuetify.breakpoint.mobile ? 12 : 5"
      >
        <v-row
          v-for="(skill, i) in skills"
          :key="i"
          :style="{
            color: $vuetify.theme.currentTheme.primary,
            width: '100%'
          }"
        >
          <v-col
            class="text-uppercase"
            cols="12"
            v-text="skill.category"
          />

          <v-progress-linear
            v-for="(item, j) in skill.items"
            :key="j"
            class="mb-2"
            :value="calculateProgress(item.displayedValue)"
            color="warning"
            height="25"
          >
            <template v-slot:default>
              <v-row>
                <v-col
                  cols="4"
                  class="text-center"
                  :style="{
                    borderRight: '2px solid',
                    backgroundColor: $vuetify.theme.currentTheme.primary,
                  }"
                >
                  <span class="warning--text">{{ item.name }}</span>
                </v-col>
                <v-col
                  cols="8"
                  class="text-center"
                >
                  <strong class="primary--text">{{ Math.ceil(item.displayedValue) }}%</strong>
                </v-col>
              </v-row>
            </template>
          </v-progress-linear>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
  import { nav } from '@/components/mixins/nav'

  export default {
    mixins: [nav],
    data: () => ({
      animations: false,
      interval: 0,
      skills: [
        {
          category: 'DATABASE',
          items: [
            {
              name: 'MySQL',
              value: 90,
              displayedValue: 0,
            },
          ],
        },
        {
          category: 'Back-end',
          items: [
            {
              name: 'PHP',
              value: 90,
              displayedValue: 0,
            },
          ],
        },
        {
          category: 'Front-end',
          items: [
            {
              name: 'Javascript',
              value: 90,
              displayedValue: 0,
            },
            {
              name: 'Vuejs',
              value: 80,
              displayedValue: 0,
            },
            {
              name: 'Angular',
              value: 80,
              displayedValue: 0,
            },
            {
              name: 'CSS',
              value: 70,
              displayedValue: 0,
            },
            {
              name: 'HTML',
              value: 70,
              displayedValue: 0,
            },
          ],
        },
        {
          category: 'Framework',
          items: [
            {
              name: 'Symfony',
              value: 80,
              displayedValue: 0,
            },
            {
              name: 'CodeIgniter',
              value: 50,
              displayedValue: 0,
            },
          ],
        },
      ],
    }),
    methods: {
      startAnimations: function (isVisible) {
        if (isVisible && !this.animations) {
          this.displayProgress(5)
          this.animations = true
        }
      },
      calculateProgress: (value) => {
        return 100 / 3 + (value * 2 / 3)
      },
      displayProgress: function (incrementStep) {
        this.skills.forEach((skill, i) => {
          skill.items.forEach(item => {
            setTimeout(() => this.incrementDelay(item, incrementStep), i * 500)
          })
        })
      },
      incrementDelay: function (item, incrementStep) {
        item.displayedValue += incrementStep
        if (item.displayedValue < item.value) {
          setTimeout(() => this.incrementDelay(item, incrementStep), 10)
        }
      },
    },
  }
</script>
